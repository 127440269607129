.paragraphResults {
    max-height: 370px;
    overflow: scroll;
}

.paragraphResults h1 {
    font-size: 16px;
}

.paragraphResults ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

h1.resultsHead {
    background: var(--light_brown);
    font-size: 0.8em;
    font-style: italic;
    margin-bottom: 5px;
}

#subtitleResults {
    padding-top: 10px;
    padding-bottom: 10px;
}

#subtitleResults li {
    font-size: 20px;
}