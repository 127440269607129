.tabButton {
    display: inline-block;
    height: 29px;
    font-size: 0.7em;
    text-align: center;
    letter-spacing: 0.1em;
    border: none;
    background-color: var(--brown);
    color: white;
}

.tabButton.clicked {
    background-color: rgb(77, 71, 71);
}
