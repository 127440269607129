#homeHeader h1 {
    font-size: 2.5em;
    line-height: 1.1em;
}

/*mobile*/

.mobile #homeHeader {
    padding-top: 12px;
    padding-bottom: 12px;
}

.mobile #homeHeader h1 {
    font-size: 2em;
    margin-bottom: 5px 0;
}

.mobile #homeHeader p {
    font-size: 0.8em;
}

/*tablet*/

.tablet #homeHeader h1 {
    font-size: 2em;
}

.tablet #homeHeader p {
    font-size: 0.9em;
}