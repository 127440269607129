.decision {
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 2px;
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.decision.approved {
    border-bottom-color: var(--approvedColor);
}

.decision.rejected {
    border-bottom-color: var(--rejectedColor);
}

.decision.enforcement {
    border-bottom-color: var(--enforcementColor);
}

.decision.openApplication {
    border-bottom-color: var(--openColor)
}

.decisionType {
    font-weight: 600;
}

.approved .decisionType {
    color: var(--approvedColor);
}

.rejected .decisionType {
    color: var(--rejectedColor);
}

.enforcement .decisionType {
    color: var(--enforcementColor);
}

.openApplication .decisionType {
    color: var(--openColor);
}

.decisionLevelAcronym {
    display: flex;
    align-items: flex-end;
    margin-left: 5px;
}

.decisionLevelAcronym span {
    font-weight: 600;
}

.decisionLevelAcronym .low {
    color: rgb(150, 85, 1);
}

.decisionLevelAcronym .medium {
    color: rgb(126, 125, 125);
}

.decisionLevelAcronym .high {
    color: rgb(196, 167, 2);
}

.applicationNumber {
    font-size: 10px;
    font-style: italic;
    margin-bottom: 2px;
}

.date {
    margin-bottom: 1px;
}

.developmentAddress {
    margin-bottom: 1px;
}