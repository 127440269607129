#homeDescription span {
    display: block;
    font-size: 1.5em;
    line-height: 1.2em;
    margin-bottom: 7px;
}

#stats {
    padding-top: 0;
}

/*tablet*/
.tablet #homeDescription span {
    font-size: 1.1em;
}

.tablet #homeDescription p {
    font-size: 0.9em;
}