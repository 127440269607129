#planningApplications {
    --approvedColor: rgb(1, 78, 1);
    --rejectedColor: maroon;
    --enforcementColor: black;
    --openColor: rgb(199, 131, 4);
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: 10px;
    background-color: rgb(250, 248, 248);
    border-radius: 4px;
    width: 240px;
    font-size: 11px;
    overflow: scroll;
    word-wrap: break-word;
    max-height: 70%;
    padding: 7px;
    box-sizing: border-box;
}

.mobile #planningApplications {
    position: static;
    width: 100%;
}

.mobile .hideButton {
    flex-flow: row;
}

.awaitingClick {
    cursor: pointer;
}

#planningApplications li {
    padding-bottom: 10px;
    margin-left: -4px;
    margin-right: -4px;
    padding-left: 4px;
    margin-bottom: 10px;
    padding-right: 4px;
    border-bottom: 1.5px solid var(--very-dark-brown);
}

#planningApplications li:first-of-type {
    padding-top: 0px;
}

#planningApplications li:last-of-type {
    padding-bottom: 0px;
    border-bottom: none;
}

#planningApplications h1 {
    font-size: 1.3em;
}

#planningApplications span {
    display: block;
}

#planningApplications ul {
    padding: 0;
}

.loading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hoverAndClick {
    margin-bottom: 5px;
}

.hideApplications {
    margin-top: 10px;
}

.checkbox label {
    font-size: 12px;
}

.hideApplications {
    border-radius: 4px;
    margin-top: 5px
}

.hideButton {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    height: 25px;
}

.hideButton img.icon {
    height: 20px;
}