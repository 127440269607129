#contents h2 {
    font-size: 1.2em;
    padding-top: 3px;
    padding-bottom: 3px;
}

#contents h3 {
    padding-top: 2px;
    padding-bottom: 2px;
}

#contents h2:hover, #contents h3:hover {
    color: rgb(73, 42, 12);
    cursor: pointer;
}

.titleSection {
    border-bottom: 1px solid gray;
    padding: 6px 0;
}

.titleSection:first-of-type {
    padding-top: 0;
    margin-top: -3px;
}

.titleSection:last-of-type {
    border: none;
}

.clickedSubtitle {
    font-style: italic;
}
