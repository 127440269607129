#copyButton {
    height: 30px;
    margin: auto;
    width: 15%;
    font-size: 12px;
    display: inline-block;
    border-radius: 2px;
    vertical-align: middle;
    color: white;
}

#copyButton:active {
    background-color: black;
}
