.bannerImage {
    width: 100%;
    height: 267px;
    object-fit: cover;
    display: block;
}

#bannerCaption {
    background-color: var(--brown);
    color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 0.85em;
    z-index: 2;
}

/*mobile options*/

.mobile .banner {
    display: none;
}

/*tablet options*/

.tablet .bannerImage {
    height: 150px;
}

.tablet #bannerCaption {
    font-size: 0.65em;
}