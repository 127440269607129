.paragraphResult {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin-bottom: -1px;
    cursor: pointer;
}

.paragraphResult:last-of-type {
    border-bottom: none;
}

.paragraphResult h1 {
    font-size: 14px;
    font-style: normal;
}

.paragraphResult h2 {
    font-size: 20px;
}

.paragraphResult h3 {
    font-size: 12px;
    margin-bottom: 4px;
}

.paragraphResultExtract {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 0.9em;
}
