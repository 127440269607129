#searchDocumentInput {
    border-width: 0px;
    font-size: 1em;
    font-style: italic;
    padding-top: 7px;
    padding-bottom: 7px;
}

#searchDocumentInput:focus {
    outline: none;
    box-shadow: none;
    background-color: black;
    color: white;
}

#searchDocumentInput::placeholder {
    color: var(--light_brown);
    font-weight: 100;
    font-style: italic;
    font-family: 'Open Sans', 'sans-serif';
}
