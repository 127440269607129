.developmentDescription {
    font-size: 12px;
    font-weight: 600;
    margin-top: 6px;
    padding-bottom: 6px;
}

.expandedInfo .fullWidthBar {
    border-radius: 4px;
}

.iconButton.planningApplicationButton {
    padding-left: 3px;
    padding-right: 3px;
    justify-content: space-between;
    height: 25px;
    border-radius: 4px;
}

.commentButton {
    background-color: green;
}

.camdenButton img {
    height: 25px;
}

.commentBar {
    margin-top: 4px;
}

.commentButton img {
    height: 19px;
}

.expandedInfo {
    border-top: 1px solid gray;
    margin-top: 5px;
}