#heritage {
    --heritage-padding: 7px;
    width: 350px;
    border-radius: 4px;
    overflow: scroll;
    z-index: 1;
    background-color: rgb(228, 227, 227);
    position: absolute;
    top: 10px;
    left: 10px;
    max-height: 95vh;
    padding: 0;
}

/*mobile*/
#heritage.mobile {
    position: static;
    width: 100%;
    border-radius: 0;
}

/*tablet*/
#heritage.tablet {
    width: 280px;
}

/*general classes*/

.fullWidth {
    width: 100%;
    box-sizing: border-box;
}

.padded {
    padding: var(--heritage-padding);
}

.verticalPadded {
    padding-top: var(--heritage-padding);
    padding-bottom: var(--heritage-padding);
}

.smallText {
    font-size: 0.8em;
}

.grow {
    flex-basis: 200px;
    flex-grow: 1;
    overflow: scroll;
    /*padding for scroll bar*/
    padding-right: 15px;
}

.centred {
    display: flex;
    justify-content: center;
}

.header {
    background-color: white;
}

.hiddenTab {
    display: none;
}

.openTab {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    min-height: 0;
}
