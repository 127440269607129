.headerText h1{
   font-size: 12px;
   font-style: italic;
}

.headerText h2 {
    font-size: 24px;
}

.headerText {
    width: 80%;
    display: inline-block;
}
