#slideUp {
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 100vw;
}

#tabButtons {
    display: flex;
    width: 100%;
    height: 30px;
}

.slideContentVisible {
    transition: max-height 0.1s ease-in-out;
    max-height: 60vh;
}

.slideContentInvisible {
    transition: max-height 0.1s ease-in-out;
    max-height: 0;
}

.slideContentVisible {
    display: block;
}

.slideContentInvisible {
    display: none;
}

#hideSlideButton {
    background-color: white;
    color: var(--brown);
    justify-content: center;
    font-size: 1.1em;
    padding: 12px;
    border-top-right-radius: 4px;
}

#tabContent {
    background-color: white;
}